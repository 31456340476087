<template>
  <form class="new-ticket" @click="isFocusMultipleField = false">
    <div class="new-ticket__header">
      <Heading level="4">{{ $t('dashboard.info_about_auto') }}</Heading>
    </div>

    <template v-if="isFetchedInfo">
      <div class="new-ticket__form-group">
        <div class="new-ticket__form-group-item plate_number">
          <label class="new-ticket__lables">
            {{ $t('new_ticket_info.auto_plate_number') }}
          </label>
          <MultipleField
            v-model="carNumber"
            :items="countries"
            :is-focus="isFocusMultipleField"
            :placeholder="$t('form.plate_number')"
            class="new-ticket__inputs"
            :error="
              ($v.carNumber.value.$dirty && !$v.carNumber.value.required) ||
              ($v.carNumber.value.$dirty && $v.carNumber.value.$invalid)
            "
            :error-txt="carNumberErrorText"
            @click.native.stop
            @focus="clearCarNumberField"
            @blur="blurCarNumberHandler"
          />
        </div>
        <div class="new-ticket__form-group-item brand">
          <label class="new-ticket__lables">
            {{ $t('new_ticket_info.brand') }}
          </label>
          <SelectField
            v-model="carBrand"
            :items="carsBrandsList"
            item-text="title"
            append-to=".new-ticket__form-group-item.brand .autocomplete-box"
            class="new-ticket__inputs"
            :error="$v.carBrand.$dirty && !$v.carBrand.required"
            :error-txt="carBrandErrorText"
            @select="fetchAllCarModels"
            @focus="clearCarBrandField"
            @blur="$v.carBrand.$touch()"
          />
        </div>

        <div class="new-ticket__form-group-item model">
          <label class="new-ticket__lables">
            {{ $t('new_ticket_info.model') }}
          </label>
          <SelectField
            v-model="carModel"
            :items="carModelsList"
            item-text="title"
            append-to=".new-ticket__form-group-item.model .autocomplete-box"
            class="new-ticket__inputs"
            :error="$v.carModel.$dirty && !$v.carModel.required"
            :error-txt="carModelErrorText"
            @focus="clearCarModelField"
            @blur="$v.carModel.$touch()"
          />
        </div>

        <div class="new-ticket__form-group-item full-name">
          <label class="new-ticket__lables" for="name">
            {{ $t('new_ticket_info.driver_name') }}
          </label>
          <TextField
            id="name"
            v-model.trim="driverFullName"
            name="name"
            required
            class="new-ticket__inputs"
            type="text"
            :error="
              ($v.driverFullName.$dirty && !$v.driverFullName.required) ||
              ($v.driverFullName.$dirty && $v.driverFullName.$invalid)
            "
            :error-txt="driverFullNameErrorText"
            @isFocus="clearDriverFullNameField"
            @blur="$v.driverFullName.$touch()"
            @input="inputFullNameHandler"
          />
        </div>
        <div class="new-ticket__form-group-item passengers">
          <label class="new-ticket__lables" for="passengers">
            {{ $t('new_ticket_info.passangers') }}
          </label>
          <TextField
            id="passengers"
            v-model.trim="passengersCount"
            type="number"
            name="number"
            required
            class="new-ticket__inputs"
            :error="
              ($v.passengersCount.$dirty && !$v.passengersCount.required) ||
              ($v.passengersCount.$dirty && $v.passengersCount.$invalid)
            "
            :error-txt="passengersCountErrorText"
            @isFocus="clearPassengersCountField"
            @blur="$v.passengersCount.$touch()"
          />
        </div>

        <div class="new-ticket__form-group-item phone">
          <label class="new-ticket__lables" for="phone">
            {{ $t('new_ticket_info.phone_number') }}
          </label>
          <TextField
            id="phone"
            v-model.trim="driverPhone"
            type="text"
            name="phone"
            required
            placeholder="+ __ ___ ___ __ __"
            class="new-ticket__inputs"
            :error="
              ($v.driverPhone.$dirty && !$v.driverPhone.required) ||
              ($v.driverPhone.$dirty && $v.driverPhone.$invalid)
            "
            :error-txt="driverPhoneErrorText"
            @isFocus="clearDriverPhoneField"
            @blur="$v.driverPhone.$touch()"
          />
        </div>
      </div>

      <div class="new-ticket__form-select">
        <Heading level="4">{{ $t('new_ticket_info.type_of_auto') }}</Heading>
        <div class="new-ticket__form-select-label">
          <div class="new-ticket__form-select-label-btn">
            <span class="new-ticket__btn-titles">
              {{ $t('new_ticket_info.auto_truck') }}
            </span>
            <span class="new-ticket__btn-values">
              ({{ ticketPricingRange }} грн)
            </span>
          </div>
        </div>
        <div class="new-ticket__form-group-item">
          <Heading level="4">
            {{ $t('new_ticket_info.subtype_auto') }}
          </Heading>
          <div class="new-ticket__btn-form">
            <ButtonBase
              v-for="item in formattedTicketPricing"
              :key="item.type_auto"
              color="white"
              size="m"
              class="new-ticket__btn-subtype"
              :class="{
                active: selectedCarType
                  ? item.type_auto === selectedCarType.name
                  : null,
              }"
              @click.native="selectCarTypeHandler(item)"
            >
              <span class="new-ticket__btn-titles">
                {{ $t(`new_ticket_info.${item.type_auto}`) }}
              </span>
              <span class="new-ticket__btn-values">
                ({{ item.price }} грн)
              </span>
            </ButtonBase>
          </div>
        </div>
      </div>

      <div class="new-ticket__footer">
        <!-- <Heading v-if="selectedCarType" level="3" class="new-ticket__price"
          >Ціна: {{ ticketPrice }} грн.</Heading
        > -->
        <SignupControlButtons
          :back-button-text="$t('signup.button.back')"
          :go-button-text="$t('signup.button.continue')"
          :is-disabled-go-btn="!isValidForm"
          @go-back="goBack"
          @go-on="submitHandler"
        />
      </div>
    </template>
    <Loader v-else />
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import validate from '@/mixins/validation/newTicket';
import { setToLS, getFromLS, isLSHasItem } from '@/library/helpers';
import Heading from '@/elements/Heading/Heading.vue';

import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import SignupControlButtons from '@/components/Signup/SignupControlButtons/SignupControlButtons.vue';

import TextField from '@/elements/Inputs/TextField.vue';
import MultipleField from '@/elements/Inputs/MultipleField.vue';
import SelectField from '@/elements/Inputs/SelectField.vue';

import Loader from '@/elements/Loader/Loader.vue';

export default {
  components: {
    Heading,
    TextField,
    ButtonBase,
    MultipleField,
    SelectField,
    SignupControlButtons,
    Loader,
  },
  mixins: [validate],
  data() {
    return {
      customsPost: getFromLS('new-ticket').customs_post,
      driverFullName: '',
      driverPhone: '',

      countries: [],
      carNumber: {
        country: {
          id: 223,
          iso: 'UA',
          iso3: 'UKR',
          name: 'UKRAINE',
          'number-code': '804',
          'phone-code': '380',
          'simple-name': 'Ukraine',
        },
        value: '',
      },

      carsBrandsList: [],
      carModelsList: [],
      carBrand: null,
      carModel: null,

      passengersCount: 1,

      ticketPricing: [],
      selectedCarType: null,

      ticketPrice: 0,
      modal: false,
      isFocusMultipleField: false,
    };
  },

  computed: {
    ...mapGetters(['getUser']),
    isFetchedInfo() {
      return (
        this.ticketPricing.length &&
        this.carsBrandsList.length &&
        this.countries.length
      );
    },
    formattedTicketPricing() {
      return this.ticketPricing.map((item) => {
        return {
          ...item,
          price: parseInt(item.price).toFixed(),
        };
      });
    },
    ticketPricingRange() {
      const ticketPricingList = this.formattedTicketPricing.map((item) =>
        parseInt(item.price)
      );
      const minPrice = Math.min(...ticketPricingList);
      const maxPrice = Math.max(...ticketPricingList);

      return `${minPrice}-${maxPrice}`;
    },

    driverFullNameArray() {
      return this.driverFullName.split(' ');
    },

    driverLastName() {
      return this.driverFullNameArray[0];
    },

    driverFirstName() {
      return this.driverFullNameArray[1];
    },

    driverSecondName() {
      return this.driverFullNameArray[2];
    },

    ticketInfoFormData() {
      const ticket = {
        last_name: this.driverLastName,
        first_name: this.driverFirstName,
        telephone_number: this.driverPhone,
        type_auto: this.selectedCarType ? this.selectedCarType.name : null,
        ticket_price: this.ticketPrice,
        country_registration: this.carNumber.country,
        auto_registration_plate: this.carNumber.value.toUpperCase(),
        car_brand: this.carBrand,
        car_model: this.carModel,
        count_of_passengers: this.passengersCount,
      };

      if (this.driverSecondName) {
        ticket.second_name = this.driverSecondName;
      }

      return ticket;
    },
    isValidForm() {
      return !this.$v.$invalid && !!this.selectedCarType;
    },
  },
  created() {
    const secondName = this.getUser?.second_name
      ? ` ${this.getUser.second_name}`
      : '';

    this.driverFullName = `${this.getUser.last_name} ${this.getUser.first_name}${secondName}`;
    this.driverPhone = `+${this.getUser.telephone_number}`;
  },
  mounted() {
    this.fetchTicketPricing();
    this.fetchAllCarBrands();
    this.fetchAllCountries();
  },
  methods: {
    async fetchTicketPricing() {
      const res = await this.$store.dispatch(
        'getTicketPricing',
        this.customsPost
      );
      this.ticketPricing = res;
    },
    async fetchAllCarBrands() {
      const res = await this.$store.dispatch('getAllCarBrands');
      this.carsBrandsList = res;
    },
    async fetchAllCarModels() {
      const res = await this.$store.dispatch(
        'getAllCarModels',
        this.carBrand.id
      );
      this.carModelsList = res;
    },
    async fetchAllCountries() {
      const res = await this.$store.dispatch('getAllCountries');
      this.countries = res;
    },
    clearCarNumberField() {
      this.isFocusMultipleField = true;
      this.$v.carNumber.value.$reset();
    },
    clearCarBrandField() {
      this.$v.carBrand.$reset();
    },
    clearCarModelField() {
      this.$v.carModel.$reset();
    },
    clearDriverFullNameField() {
      this.$v.driverFullName.$reset();
    },
    clearPassengersCountField() {
      this.$v.passengersCount.$reset();
    },
    clearDriverPhoneField() {
      this.$v.driverPhone.$reset();
    },
    blurCarNumberHandler() {
      this.isFocusMultipleField = false;
      this.$v.carNumber.value.$touch();
    },
    inputFullNameHandler(fullName) {
      this.driverFullName = fullName
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    updateNewTicketInLS() {
      if (isLSHasItem('new-ticket')) {
        const updatedNewTicket = {
          ...getFromLS('new-ticket'),
          ...this.ticketInfoFormData,
        };
        setToLS('new-ticket', updatedNewTicket);
      }
    },
    goBack() {
      this.$router.push({ name: 'NewTicketCustomsPost' });
    },

    submitHandler() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.updateNewTicketInLS();
      this.goToNextStep();
    },

    goToNextStep() {
      this.$router.push({ name: 'NewTicketDate' });
    },

    openModal() {
      this.modal = true;
    },

    closeModal() {
      this.modal = false;
    },

    selectCarTypeHandler(item) {
      this.selectedCarType = {
        name: item.type_auto,
        price: item.price,
      };

      this.ticketPrice = this.selectedCarType.price;
    },

    acceptResetFormHandler() {
      this.closeModal();
      this.resetForm();
    },
  },
};
</script>
<style lang="sass">
.new-ticket .loader
  height: 854px
  @media screen and (max-width: 1199px)
    height: 970px
.new-ticket__form-group
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(365px, 1fr))
  column-gap: 20px
  row-gap: 32px
  padding: 0 0 48px
  margin-bottom: 8px
  border-bottom: 1px solid var(--primary-color-light-grey-2)

  @media screen and (max-width: 1199px)
    grid-template-columns: repeat(auto-fill, minmax(325px, 1fr))
    padding: 0 0 40px

  @media screen and (max-width: 600px)
    grid-template-columns: repeat(auto-fill, minmax(288px, 1fr))
    padding: 0 0 24px
    row-gap: 24px

.full-name
  @media screen and (max-width: 1199px)
    order: 0

.brand
  @media screen and (max-width: 1199px)
    order: 1

.model
  @media screen and (max-width: 1199px)
    order: 2

.passengers
  @media screen and (max-width: 1199px)
    order: 3

.phone
  @media screen and (max-width: 1199px)
    order: 4

.new-ticket__lables
  color: var(--primary-color-dark-blue)
  opacity: 0.6
  pointer-events: none

.new-ticket__inputs.multi-select-box
  @media screen and (max-width: 600px)
    height: 44px

.new-ticket__inputs .input,
.new-ticket__inputs .v-autocomplete .v-input__slot,
.full-name .input-box .input,
.full-name .input-box .input
  @media screen and (max-width: 600px)
    font-size: 14px
    height: 44px
    padding: 12px

.new-ticket__inputs.multi-select-box .input-box .input
  @media screen and (max-width: 600px)
    height: 40px
    font-size: 14px

.new-ticket__form-group-item
  display: flex
  flex-direction: column
  gap: 8px

.new-ticket__header
  display: flex
  align-items: center
  justify-content: space-between

.new-ticket__form-select
  display: flex
  flex-direction: column

  .new-ticket__form-group-item
    gap: 0

.new-ticket__form-select-label
  display: flex
  flex-direction: column
  align-items: flex-start
  padding-bottom: 48px
  margin-bottom: 8px
  border-bottom: 1px solid var(--primary-color-light-grey-2)

  @media screen and (max-width: 768px)
    padding-bottom: 40px
    margin-bottom: 16px

  @media screen and (max-width: 600px)
    padding-bottom: 24px
    margin-bottom: 0

.new-ticket__form-select-label-btn
  align-items: center
  justify-content: center
  width: 220px
  height: 64px
  background: var(--btn-color-blue-focus)
  color: var(--primary-color-white)
  box-shadow: 0px 32px 80px rgba(38, 55, 76, 0.05)
  border-radius: var(--primary-border-radius)

.new-ticket__form-select-label-btn,
.new-ticket__btn-subtype,
.new-ticket__btn-form .primary-btn
  display: flex
  flex-direction: column
  font-weight: 600
  text-transform: unset!important

  @media screen and (max-width: 768px)
    width: 200px

  @media screen and (max-width: 600px)
    width: 150px
    font-size: 12px
    height: 50px
    gap: 2px

  @media screen and (max-width: 350px)
    width: 136px
    height: 44px

.new-ticket__form-select-label-btn span,
.new-ticket__btn-form .primary-btn span
  @media screen and (max-width: 470px)
    line-height: 14px

.new-ticket__btn-form-footer .primary-btn
  text-transform: uppercase!important
  width: 240px
  line-height: 14px

.new-ticket__btn-subtype.active
  border: none!important
  background-color: var(--secondary-color-dark-blue)!important
  color: var(--primary-color-white)!important

.new-ticket__btn-titles
  font-size: 18px
  line-height: 24px

  @media screen and (max-width: 600px)
    font-size: 14px
    line-height: 16px

  @media screen and (max-width: 350px)
    font-size: 12px
    line-height: 14px

.new-ticket__btn-values
  font-weight: normal
  font-size: 16px
  line-height: 24px

  @media screen and (max-width: 600px)
    font-size: 12px
    line-height: 14px

  @media screen and (max-width: 350px)
    font-size: 10px
    line-height: 12px

.new-ticket__btn-form
  display: flex
  gap: 20px
  position: relative
  flex-wrap: wrap

.new-ticket__btn-form-footer
  gap: 32px
  flex-wrap: nowrap

.new-ticket__btn-form,
.new-ticket__btn-form-footer
  @media screen and (max-width: 600px)
    gap: 16px

.new-ticket__btn-form-footer .primary-btn
  @media screen and (max-width: 600px)
    width: 200px
  @media screen and (max-width: 470px)
    width: 136px
    height: 44px

.new-ticket__price
  margin-top: 0
</style>
