import {
  required,
  minLength,
  maxLength,
  email,
  between,
  numeric,
  alphaNum,
  helpers,
} from 'vuelidate/lib/validators';

import VueI18n from '@/i18n';

const fullName = helpers.regex(
  'alpha',
  /^([A-Za-z-]|[а-щА-ЩЬьЪъЫыЭэЮюЯяЇїІіЄєҐґёЁ'-])+\s(([A-Za-z-]|[а-щА-ЩЬьЪъЫыЭэЮюЯяЇїІіЄєҐґёЁ'-])(\s)?)+$/
);
const phone = helpers.regex('number', /^\+[0-9]+$/);
const carNumber = helpers.regex('alphaNum', /^[A-Za-z0-9]+$/);

export default {
  validations: {
    driverFullName: {
      required,
      alphaCheck: fullName,
    },
    driverPhone: {
      required,
      numeric: phone,
      minLength: minLength(11),
    },
    carNumber: {
      value: {
        required,
        alphaNum: carNumber,
      },
    },
    carBrand: {
      required,
    },
    carModel: {
      required,
    },
    passengersCount: {
      required,
      numeric,
      between: between(1, 100),
    },
  },
  computed: {
    driverFullNameErrorText() {
      if (!this.$v.driverFullName.required) {
        return VueI18n.t('input_validation.driver_fullname.empty');
      }
      if (!this.$v.driverFullName.alphaCheck) {
        return VueI18n.t('input_validation.driver_fullname.alpha_check');
      }
    },
    driverPhoneErrorText() {
      if (!this.$v.driverPhone.required) {
        return VueI18n.t('input_validation.driver_phone.empty');
      }
      if (!this.$v.driverPhone.numeric) {
        return VueI18n.t('input_validation.driver_phone.numeric');
      }
      if (!this.$v.driverPhone.minLength) {
        return VueI18n.t('input_validation.driver_phone.min_length');
      }
    },
    carNumberErrorText() {
      if (!this.$v.carNumber.value.required) {
        return VueI18n.t('input_validation.car_number.empty');
      }
      if (!this.$v.carNumber.value.alphaNum) {
        return VueI18n.t('input_validation.car_number.alpha_num');
      }
    },
    carBrandErrorText() {
      if (this.$v.carBrand.$invalid) {
        return VueI18n.t('input_validation.car_brand.empty');
      }
    },
    carModelErrorText() {
      if (this.$v.carModel.$invalid) {
        return VueI18n.t('input_validation.car_model.empty');
      }
    },
    passengersCountErrorText() {
      if (!this.$v.passengersCount.required) {
        return VueI18n.t('input_validation.passengers_count.empty');
      }
      if (!this.$v.passengersCount.between) {
        return VueI18n.t('input_validation.passengers_count.between');
      }
    },
  },
};
